import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
// import { rgba } from 'polished';

import Content from './Content';
import theme from './theme';

const FAQ = ({ slug, question, _rawAnswer, id }) => {
  const [open, toggle] = useState(false);
  const [height, setHeight] = useState(0);
  const [focus, toggleFocus] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.scrollHeight);
    }
  }, []);

  const spring = useSpring({
    maxHeight: open ? `${height}px` : '0px',
  });

  const buttonSpring = useSpring({
    color: open ? theme.red : theme.black,
  });

  return (
    <FaqWrapper className="faq" id={slug.current} focus={focus} open={open}>
      <Header className="faq__heading" open={open}>
        <animated.button
          className="faq__question"
          aria-expanded={open}
          aria-controls={id}
          onClick={() => toggle(!open)}
          id={`${id}-label`}
          onFocus={() => toggleFocus(true)}
          onBlur={() => toggleFocus(false)}
          style={buttonSpring}
        >
          {question}
        </animated.button>
      </Header>

      <Answer
        style={spring}
        className="faq__answer"
        id={id}
        ref={ref}
        aria-labelledby={`${id}-label`}
        hidden={!open}
      >
        {_rawAnswer}
      </Answer>
    </FaqWrapper>
  );
};

const FaqWrapper = styled.div`
  width: ${({ theme }) => theme.sizes.content}px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  ::after {
    content: '';
    height: 2px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: ${({ theme }) => theme.black};
    opacity: 0.25;
    display: block;
    margin-bottom: 2rem;
    transition: 0.25s;
    transform-origin: left;
    /* width: ${({ focus }) => (focus ? '100%' : '0%')}; */
    width: 100%;
    transform: ${({ focus, open }) =>
      focus || open ? 'scaleX(1)' : 'scaleX(0)'};
  }
  :hover::after {
    width: 100%;
    transform: scaleX(1);
  }
`;

const Header = styled.h3`
  margin-bottom: 2rem;
  margin-top: 0;
  /* padding-top: 2rem; */

  button {
    background: none;
    border: 0;
    text-align: left;
    display: inline;
    padding: 0;
    font-weight: ${({ theme }) => theme.font.medium};
    font-size: 2rem;
    /* color: ${({ theme, open }) => (open ? theme.orange : theme.black)}; */
    outline: none;
    padding-bottom: 0.5rem;
    cursor: pointer;
  }
`;

const Answer = styled(Content)`
  overflow: hidden;
  /* margin-bottom: 2rem; */
`;

export default FAQ;
