import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import FAQ from './FAQ';
import Content from './Content';

const InfoPage = ({ _rawContent, title, faqs }) => {
  const {
    file: {
      childImageSharp: {
        sizes: { src },
      },
    },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "RBR-elements-02.png" }) {
        id
        childImageSharp {
          sizes {
            src
          }
        }
      }
    }
  `);

  return (
    <PageWrapper className="page-wrapper" bg={src}>
      <h1 className="page-title">{title}</h1>
      {_rawContent && (
        <Content className="block-content-wrapper infopage-content">
          {_rawContent}
        </Content>
      )}
      <div className="block-content-wrapper">
        {faqs.map(({ node }) => (
          <FAQ key={node.id} {...node} />
        ))}
      </div>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  .page-title {
    text-align: center;
    font-size: 4rem;
    position: relative;
    padding: 8rem 0 5rem;
    ::after,
    ::before {
      position: absolute;
      width: 50%;
      height: 100%;
      background: ${({ bg }) => `url(${bg})`};
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      content: '';
    }
    ::after {
      left: 50%;
      top: 0;
    }
    ::before {
      right: 50%;
      top: 0;
      transform: rotateY(180deg);
    }
  }
  .block-content-wrapper {
    padding: 1rem;
  }
`;

export default InfoPage;
