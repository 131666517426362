import React from 'react';
import { graphql } from 'gatsby';

import Wrapper from '../components/Wrapper';
import InfoPage from '../components/InfoPage';

const info = ({ data }) => {
  return (
    <Wrapper className="page info-page">
      <InfoPage {...data.sanityInfoPage} faqs={data.allSanityFaq.edges} />
    </Wrapper>
  );
};

export const InfoPageQuery = graphql`
  query infoPage {
    sanityInfoPage {
      _rawContent(resolveReferences: { maxDepth: 10 })
      title
    }
    allSanityFaq(sort: { fields: _updatedAt, order: ASC }) {
      edges {
        node {
          id
          question
          slug {
            current
          }
          _rawAnswer(resolveReferences: { maxDepth: 10 })
        }
      }
    }
  }
`;

export default info;
